import React from 'react'
import Layout from "../components/layout"

export default function AboutPage() {
    return (
        <Layout>
            <h1>About C&amp;F Solutions</h1>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio ducimus fugit natus nesciunt tempore quia ullam assumenda voluptatibus, rerum voluptatum eaque ex perspiciatis mollitia praesentium neque numquam illo accusantium nihil, doloremque, animi unde veritatis nemo corporis libero! Numquam, cumque culpa?</p>
        </Layout>
    )
}
